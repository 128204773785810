import React from "react";

export default function NeoLibLoader({ neo, envConfig }) {
    React.useEffect(() => {
        let script;
        if (!neo || !envConfig.initDone) {
            return;
        }
        function loadNeoLibrary(neoEnv) {
            const body = document.body;
            script = document.createElement("script");
            script.type = "text/javascript";
            script.src = `https://${neoEnv}/MWC/mwc-app.js`;
            script.onerror = function () {
                neo.initError();
            };
            body.appendChild(script);
        }
        loadNeoLibrary(envConfig.env.neoEnv);
        return () => script && document.body.removeChild(script);
    }, [neo, envConfig]);

    return null;
}
