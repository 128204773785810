import React, { lazy, Suspense } from "react";
import logo from "../images/PlayAlberta_logo.png";
import styles from "./app.module.css";
import useEnvConfig from "../hooks/useEnvConfig";
import NeoLibLoader from "./util/NeoLibLoader";
import { NeoProvider } from "../neo";
import { connect } from "react-redux";
import { fetchExternalPjp, fetchPjp, increasePjp } from "src/actions/pjpActions";
import { isDev, isNative } from "../utils";

const Root = lazy(() => import("./Root"));

const duration = 500;
const defaultTransitionStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 1,
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "center",
    alignItems: "center",
};

function AppNoRouter({ neo, ...props }) {
    const envConfig = useEnvConfig();

    const isNativeApp = isNative();

    return (
        <>
            <NeoLibLoader neo={neo} envConfig={envConfig} />
            <NeoProvider neo={neo} envConfig={envConfig}>
                {({ neoInitDone, neoInitError }) => (
                    <>
                        {!neoInitDone && !isDev() && !isNativeApp && (
                            <div
                                className={`${styles.loadingScreen}`}
                                style={{
                                    ...defaultTransitionStyle,
                                }}
                            >
                                <img
                                    src={logo}
                                    alt={"Loading App"}
                                    width={200}
                                    height={35}
                                    className={"loading-app-logo"}
                                />
                                {neoInitError && (
                                    <p className="text-danger">Something went wrong, please try again soon</p>
                                )}
                                {!neoInitError && <p className={styles.loadingText}>Loading...</p>}
                            </div>
                        )}
                        {envConfig.initDone && (
                            <Suspense fallback={""}>
                                <Root
                                    neoInitDone={neoInitDone}
                                    neo={neo}
                                    envConfig={envConfig}
                                    isNativeApp={isNativeApp}
                                />
                                {!neoInitDone && !isNativeApp && (
                                    <div
                                        className={"fixed-bottom w-100 bg-dark text-light opacity-25 text-center small"}
                                        style={{ opacity: 0.65 }}
                                    >
                                        localhost - Neo lib is still loading
                                    </div>
                                )}
                            </Suspense>
                        )}
                    </>
                )}
            </NeoProvider>
        </>
    );
}

export default connect(null, { fetchExternalPjp, fetchPjp, increasePjp })(AppNoRouter);
