import React, { useEffect, useReducer } from "react";
import useNeo from "../hooks/useNeo";
import { isNative } from "../../utils";
import { sendMessageToNative } from "../../utils/btobet";

export const initNeoUser = {
    userStatus: "loggedOut",
    userId: null,
    balanceToDisplay: "",
    validateTokenProgress: { status: "" },
    isSessionCheckPassed: false,
};

const NeoUserContext = React.createContext(initNeoUser);
export default NeoUserContext;

export function NeoUserProvider({ children }) {
    const neo = useNeo();
    const [state, setState] = useReducer((useState, action) => {
        return { ...useState, ...action };
    }, initNeoUser);

    useEffect(() => {
        function onInit() {
            if (neo.getSessionDetails()) {
                setState({
                    userStatus: "loggedIn",
                    userId: neo.getSessionDetails().PlayerID,
                    balanceToDisplay: neo.getUserBalanceToDisplay(),
                    isSessionCheckPassed: true,
                });
            } else if (neo.getSessionDetails() === null) {
                setState({
                    isSessionCheckPassed: true,
                });
            }
        }
        onInit();

        neo.registerCallback("onInit", onInit);
        return () => {
            neo.removeCallback("onInit", onInit);
        };
    }, [neo]);

    useEffect(() => {
        function onSessionUpdate(sessionUpdate) {
            if (sessionUpdate.Status === "Login") {
                setState({
                    userStatus: "loggedIn",
                    userId: sessionUpdate.PlayerID,
                    balanceToDisplay: neo.getUserBalanceToDisplay(),
                });
            } else {
                setState({
                    userStatus: "loggedOut",
                    userId: null,
                });
            }

            if (isNative()) {
                sendMessageToNative("sessionUpdate", { status: sessionUpdate["Status"] });
            }
        }

        neo.registerCallback("validatedOnSessionStatusUpdate", onSessionUpdate);
        return () => {
            neo.removeCallback("validatedOnSessionStatusUpdate", onSessionUpdate);
        };
    }, [neo]);

    useEffect(() => {
        function onProgressUpdate(statusUpdate) {
            setState({
                validateTokenProgress: statusUpdate,
            });
        }

        neo.registerCallback("validateTokenProgressUpdate", onProgressUpdate);
        return () => {
            neo.removeCallback("validateTokenProgressUpdate", onProgressUpdate);
        };
    }, [neo]);

    useEffect(() => {
        function handleBalanceUpdate({ Balance, DisplayBalance }) {
            setState({
                balanceToDisplay: DisplayBalance,
            });

            if (isNative()) {
                sendMessageToNative("balanceUpdated", {
                    balance: Balance,
                    displayBalance: DisplayBalance,
                });
            }
        }

        neo.registerCallback("onBalanceUpdated", handleBalanceUpdate);
        return () => {
            neo.removeCallback("onBalanceUpdated", handleBalanceUpdate);
        };
    }, [neo]);

    return <NeoUserContext.Provider value={state}>{children}</NeoUserContext.Provider>;
}
