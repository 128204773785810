import { isMobile } from "../../../utils/device";
import { sendMessageToNative } from "../../../utils/btobet";
import { isNative } from "../../../utils";

export function getFrameworkPreferences() {
    function getURLParams() {
        return window.location.search.replace(/^\?/, "");
    }
    return {
        BrandID: "128",
        Language: "ENG",
        Currency: "CAD",
        Platform: isMobile() ? "C" : "W",
        URLParams: getURLParams(),
        IsGameStandaloneMode: false,
        hooks: {},
        ExternalModules: {
            login:
                isNative() &&
                (() =>
                    sendMessageToNative("auth", {
                        action: "loginRequest",
                    })),
        },
    };
}
