import * as formatting from "../utils/formatting";

const SECOND_IN_MS = 1000.0;
const MINUTE = 60 * SECOND_IN_MS;
const TICKER_BACK_LOG_IN_SECONDS = 720;
export const PJP_UPDATE_INTERVAL = SECOND_IN_MS;
export const PJP_FETCH_INTERVAL = MINUTE;
export const TICKER_BACK_LOG_IN_MS = TICKER_BACK_LOG_IN_SECONDS * SECOND_IN_MS;

export const getDifferenceInSeconds = (timeA, timeB) => {
    return Math.abs(timeA - timeB) / SECOND_IN_MS;
};

export const getIncreasePerSecond = (PJPBalancePast, PJPBalanceCurrent) => {
    const currentBalance = getBalanceValue(PJPBalanceCurrent);
    const pastBalance = getBalanceValue(PJPBalancePast);
    const increasePerSecond = (currentBalance - pastBalance) / TICKER_BACK_LOG_IN_SECONDS;
    return Math.max(increasePerSecond, 0);
};

export const getBalanceValue = (balanceString) => {
    if (typeof balanceString === "number") {
        return balanceString;
    }

    const balanceStringWithoutCommas = balanceString.split(",").join("");
    return parseFloat(balanceStringWithoutCommas);
};

export const formatJackpot = (amount) => {
    return formatting.formatCurrency(amount, "CAD", { decimalPlaces: 2 });
};
