import { log } from "./logger";

export function getElementWithRetry(id, maxRetries = 3, retryDelay = 1000) {
    return new Promise((resolve) => {
        let retries = 0;

        function attempt() {
            const element = document.getElementById(id);

            if (element) {
                log(`debug Element with ID '${id}' found:`, element);
                resolve(element);
            } else {
                retries++;
                if (retries < maxRetries) {
                    log(`debug Attempt ${retries} failed. Retrying in ${retryDelay}ms...`);
                    setTimeout(attempt, retryDelay);
                } else {
                    log(`debug Element with ID '${id}' not found after ${maxRetries} attempts.`);
                    resolve(null);
                }
            }
        }

        attempt();
    });
}

export function attachHandlerOnEvent(selector, eventType, handler) {
    document.addEventListener(
        eventType,
        function (e) {
            // loop parent nodes from the target to the delegation node
            for (let target = e.target; target && target !== this; target = target.parentNode) {
                if (target.matches(selector)) {
                    handler.call(target, e, target);
                    break;
                }
            }
        },
        false
    );
}
