import {
    FETCH_ERROR_PJP_ACTION,
    FETCH_PJP_ACTION,
    FETCH_EXTERNAL_PJP_ACTION,
    INCREASE_PJP_ACTION,
} from "src/actions/pjpActions";
import { getBalanceValue, getIncreasePerSecond } from "src/utils/pjpUtils";

const initialState = {
    all: [],
    external: [],
};

const pjpReducer = (state = initialState, action) => {
    if (action.type === FETCH_EXTERNAL_PJP_ACTION) {
        return onExternalFetchSuccess(state, action.payload);
    }

    if (action.type === FETCH_PJP_ACTION) {
        return onFetchSuccess(state, action.payload);
    }

    if (action.type === INCREASE_PJP_ACTION) {
        return onTickerIncrease(state);
    }

    if (action.type === FETCH_ERROR_PJP_ACTION) {
        return onFetchError(state);
    }

    return state;
};

export default pjpReducer;

const onExternalFetchSuccess = (state, payload) => {
    return {
        ...state,
        external: payload,
    };
};

const onFetchSuccess = (state, payload) => {
    return {
        ...state,
        all: payload.map((pjp) => {
            let previousPjpState = state.all.find((jp) => jp.GlobalIdentifier === pjp.GlobalIdentifier);
            let currentPjpState = {
                ...pjp,
                value: previousPjpState?.value || getBalanceValue(pjp.PJPBalancePast),
            };
            currentPjpState.increasePerSec = getIncreasePerSecond(currentPjpState.value, pjp.PJPBalanceCurrent);

            if (
                previousPjpState?.LastWin?.LastWinTimeElapsedInSeconds >
                currentPjpState?.LastWin?.LastWinTimeElapsedInSeconds
            ) {
                currentPjpState.value = getBalanceValue(currentPjpState.PJPBalanceCurrent);
                currentPjpState.increasePerSec = 0;
            }

            return currentPjpState;
        }),
    };
};

const onTickerIncrease = (state) => {
    return {
        ...state,
        all: state.all.map((pjp) => {
            const newValue = pjp.value + pjp.increasePerSec;

            return {
                ...pjp,
                value: newValue,
            };
        }),
    };
};

const onFetchError = (state) => {
    return {
        ...state,
        all: state.all.map((pjp) => {
            let newIncreasePerSec = pjp.increasePerSec * 0.8 || 0;

            return {
                ...pjp,
                increasePerSec: newIncreasePerSec,
            };
        }),
        external: [],
    };
};
