import { CHECK_USER_ACTIVITY_FREQUENCY } from "../../../webapp_config";
import PlayerActivity from "./PlayerActivity";

class Session {
    constructor() {
        this.refreshSession = () => {};
        this.playerActivity = new PlayerActivity();
        this.stop = this.stop.bind(this);
    }
    setRefreshSessionHandler(refreshSession) {
        this.refreshSession = refreshSession;
    }
    start() {
        this.playerActivity.start();
        this.refreshSession();
        this.startRecordingUserInteraction();
        this.startCheckActivityTimer();
    }
    startRecordingUserInteraction() {
        document.addEventListener("mouseup", this.playerActivity.handleUserInteraction, true);
        document.addEventListener("scroll", this.playerActivity.handleUserInteraction, true);
    }
    startCheckActivityTimer() {
        const that = this;
        this.checkSessionId = setInterval(() => {
            this.playerActivity.checkActivity(that.refreshSession, that.stop);
        }, CHECK_USER_ACTIVITY_FREQUENCY);
    }
    stop() {
        clearInterval(this.checkSessionId);
        document.removeEventListener("mouseup", this.playerActivity.handleUserInteraction, true);
        document.removeEventListener("scroll", this.playerActivity.handleUserInteraction, true);
    }
}

export default Session;
