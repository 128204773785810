import { isNative } from "./index";
import { sendMessageToNative } from "./btobet";
import { getNeoInstance } from "../neo/neo-bridge/Neo";

export const handleDataAction = (e, target) => {
    e.preventDefault();
    const actionName = target.getAttribute("data-action");

    if (isNative()) {
        sendMessageToNative("dataAction", { action: actionName, args: getKVActionArgs(target) });
        return;
    }

    if (!hasKVActionArgs(target) && hasLegacyActionArgs(target)) {
        getNeoInstance().callNeoAction(actionName, getLegacyActionArgs(target));
        return;
    }

    getNeoInstance().callNeoAction(actionName, [getKVActionArgs(target)]);
};

export const hasKVActionArgs = (target) =>
    Object.entries(target.dataset).some(([key]) => key.startsWith("actionArg") && key !== "actionArgs");

export const hasLegacyActionArgs = (target) => !!target.getAttribute("data-action-args");

export const getKVActionArgs = (target) => {
    const args = {};

    Object.entries(target.dataset).forEach(([key, value]) => {
        if (key.startsWith("actionArg") && key !== "actionArgs") {
            const withoutPrefix = key.slice("actionArg".length);
            args[withoutPrefix.charAt(0).toLowerCase() + withoutPrefix.slice(1)] = value;
        }
    });

    return args;
};

export const getLegacyActionArgs = (target) => {
    const args = target.getAttribute("data-action-args");
    let actualArguments = [];

    if (args) {
        try {
            actualArguments = [JSON.parse(args)];
        } catch (err) {
            actualArguments = args.split("|");
        }
    }

    return actualArguments;
};
