/* global ng_fw ng_hs */
/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./utils/polyfills";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import App from "./components/App";
import { getFrameworkPreferences } from "./neo/neo-bridge/setup/aglc_setup";
import { log, warn } from "./utils/logger";
import { isMobileIOS } from "./utils/device";
import { attachHandlerOnEvent } from "./utils/dom";
import { handleDataAction } from "./utils/dataAction";
import { getNeoInstance } from "./neo/neo-bridge/Neo";

ReactDOM.render(
    <React.StrictMode>
        <App neo={getNeoInstance()} />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

window.getFrameworkPreferences = getFrameworkPreferences;

document.addEventListener("fwInitError", function () {
    warn("fwInitError");
    getNeoInstance().initError();
});

document.addEventListener("fwReady", function () {
    log("fwReady");
    getNeoInstance().init(ng_fw, ng_hs);
    attachHandlerOnEvent("[data-action]", "click", handleDataAction);
});

window.addEventListener("load", function () {
    function stopIOSFromZoomingDueNeoNon16pxInputs() {
        if (isMobileIOS(navigator.userAgent)) {
            let viewport = document.querySelector("meta[name=viewport]");
            viewport.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0");
        }
    }

    stopIOSFromZoomingDueNeoNon16pxInputs();
});
