export function isDev() {
    return process.env.NODE_ENV === "development";
}

export function isTest() {
    return process.env.NODE_ENV === "test";
}

function getFullContainers(column, containers) {
    return column.containers.map((container) => {
        const fullContainer = containers[container.identifier];
        return {
            ...fullContainer.container,
            contentlets: fullContainer.contentlets[`uuid-${container.uuid}`],
        };
    });
}

export function getUpdatedColumns(columns, containers) {
    return columns.map((column) => {
        return {
            ...column,
            containers: getFullContainers(column, containers),
        };
    });
}

export function getPageWidgetsList(containers) {
    const widgets = [];

    let contentlets = getContentlets(containers);

    for (const key in contentlets) {
        if (Object.hasOwnProperty.call(contentlets, key)) {
            const contentlet = contentlets[key];
            contentlet.forEach((element) => {
                if (element.baseType === "WIDGET") {
                    widgets.push(element);
                }
            });
        }
    }

    return widgets;
}

export function getPageContentList(containers) {
    const widgets = [];

    let contentlets = getContentlets(containers);

    for (const key in contentlets) {
        if (Object.hasOwnProperty.call(contentlets, key)) {
            const contentlet = contentlets[key];
            contentlet.forEach((element) => {
                widgets.push(element);
            });
        }
    }

    return widgets;
}

export function getContentlets(containers) {
    let contentlets = {};

    for (const key in containers) {
        if (Object.hasOwnProperty.call(containers, key)) {
            const container = containers[key];
            const containerContentlets = container.contentlets;

            Object.entries(containerContentlets).forEach(([key, values]) => {
                contentlets[key] = [...(contentlets[key] ?? []), ...values];
            });
        }
    }

    return contentlets;
}

export const getImageUrlWithQualityFilter = (imageUrl) => {
    return `${imageUrl}/50q`;
};

export function isNative() {
    return navigator.userAgent.endsWith("aglc-native");
}
