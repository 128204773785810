import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { EnvConfigProvider } from "../hooks/useEnvConfig";
import store from "src/reduxStore";
import { START_PATH } from "src/webapp_config";
import AppNoRouter from "./AppNoRouter";
import { HelmetProvider } from "react-helmet-async";

function App(props) {
    return (
        <EnvConfigProvider>
            <HelmetProvider>
                <Router basename={START_PATH}>
                    <Provider store={store}>
                        <AppNoRouter {...props} />
                    </Provider>
                </Router>
            </HelmetProvider>
        </EnvConfigProvider>
    );
}

export default App;
