import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect } from "react";
import qs from "query-string";
import { useCMSContentQuery } from "../../utils/network";
import { setOpenPageMappings } from "../neo-bridge/openPage/openPage";
import useFeatures from "../../hooks/useFeatures";

const NeoContext = React.createContext(null);

export function NeoProvider({ children, neo, envConfig }) {
    const { neoInitDone, neoInitError } = useNeoInitStatus(neo);
    const history = useHistory();
    neo.setEnvConfig(envConfig);

    const onNeoNavigate = useCallback(
        (path) => {
            history.push(path);
        },
        [history]
    );

    useEffect(() => {
        neo.doClientNavigate = onNeoNavigate;
    }, [neo, onNeoNavigate]);

    useClearURLParamsOnDemand(neo);

    return (
        <NeoContext.Provider value={neo}>
            {children({ neoInitDone, neoInitError })}
            {envConfig && envConfig.initDone ? <OpenPageLoader /> : null}
        </NeoContext.Provider>
    );
}

function OpenPageLoader() {
    const features = useFeatures();
    const { data } = useCMSContentQuery({ type: "OpenPageEvent" });

    useEffect(() => {
        const openPageData = data?.contentlets[0]?.keyCustomInfo;
        if (features.cms_open_page && openPageData) {
            setOpenPageMappings(openPageData);
        }
    }, [data, features.cms_open_page]);

    return null;
}

const neoLibraryLoadBypass = false; //process.env.NODE_ENV === "development";

function useNeoInitStatus(neo) {
    const [neoInitDone, setNeoInitDone] = React.useState(neoLibraryLoadBypass);
    const [neoInitError, setNeoInitError] = React.useState(false);

    React.useEffect(() => {
        function onInitError() {
            setNeoInitError(true);
        }

        neo.registerCallback("onInitError", onInitError);
        return () => {
            neo.removeCallback("onInitError", onInitError);
        };
    }, [neo]);

    React.useEffect(() => {
        function onInit() {
            setNeoInitDone(true);
        }

        neo.registerCallback("onInit", onInit);
        return () => {
            neo.removeCallback("onInit", onInit);
        };
    }, [neo]);
    return { neoInitDone, neoInitError };
}

function useClearURLParamsOnDemand(neo) {
    const history = useHistory();
    React.useEffect(() => {
        function clearURLParams(paramsToClear) {
            const params = qs.parse(history.location.search, { decode: true });

            for (const param of paramsToClear) {
                delete params[param];
            }

            const search = qs.stringify(params, { encode: true });

            history.replace({
                ...history.location,
                search,
            });
        }

        neo.registerCallback("clearURLParams", clearURLParams);

        return () => {
            neo.removeCallback("clearURLParams", clearURLParams);
        };
    }, [neo, history]);
}

export default NeoContext;
