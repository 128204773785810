import { isNative } from "./index";

const Origin = {
    AGLC_WEB: "aglcWeb",
    AGLC_NATIVE: "aglcNative",
};

const validOrigins = ["https://qa.playalberta.ca", "https://uat.playalberta.ca", "https://playalberta.ca"];

const itemClickEvents = {
    HOME: "home",
    BETSLIP: "betslip",
    LIVENOW: "livenow",
    MYBETS: "mybets",
};

export function onMessageEventReceived(event) {
    if (isNative()) {
        if (isNativeNavEvent(event)) {
            handleNativeNavEvent(event);
        } else if (isBtobetEvent(event)) {
            handleBtoBetEvent(event);
        }
    }
}

function handleNativeNavEvent(event) {
    const itemClicked = event.data.options?.itemClicked;
    switch (itemClicked) {
        case itemClickEvents.HOME:
            sendMessageToBtobet("footerEvent", { itemClicked: itemClickEvents.HOME });
            break;
        case itemClickEvents.BETSLIP:
            sendMessageToBtobet("footerEvent", { itemClicked: itemClickEvents.BETSLIP });
            break;
        case itemClickEvents.LIVENOW:
            sendMessageToBtobet("footerEvent", { itemClicked: itemClickEvents.LIVENOW });
            break;
        case itemClickEvents.MYBETS:
            sendMessageToBtobet("footerEvent", { itemClicked: itemClickEvents.MYBETS });
            break;
        default:
            break;
    }
}

function handleBtoBetEvent(event) {
    if (event.data.type === "betCount") {
        sendMessageToNative("betslipUpdated", {
            noOfItems: event.data.event.betCount,
            isOpen: event.data.event.isBetslipOpen,
        });
    } else if (event.data.type === "btoAppLoaded") {
        sendMessageToBtobet("footerEvent", { toggleFooter: true });
        sendMessageToBtobet("hideSettingsHeader", { dataType: true });
    }
}

function isNativeNavEvent(event) {
    return event.data && event.data.origin === Origin.AGLC_NATIVE && event.data.type === "navigation";
}

function isBtobetEvent(event) {
    return event.data && event.data.btoEvent;
}

function sendMessageToBtobet(type, eventInfo) {
    const foundOrigin = findValidOrigin();
    const event = { isMobile: true, ...eventInfo };
    if (foundOrigin) {
        window.postMessage({ btoEvent: true, type, event }, foundOrigin);
    }
}

export function sendMessageToNative(type, options) {
    const foundOrigin = findValidOrigin();
    if (foundOrigin) {
        window.postMessage({ origin: Origin.AGLC_WEB, type, options }, foundOrigin);
    }
}

function findValidOrigin() {
    return validOrigins.find((origin) => origin === window.origin);
}
