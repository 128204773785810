import { ONE_MINUTE } from "./neo/neo-bridge/session/constants";

export const START_PATH = process.env.START_PATH || "";

export const CHECK_USER_ACTIVITY_FREQUENCY = 20000;
export const MAX_SESSION_TTL = 15 * ONE_MINUTE;
export const REFRESH_SESSION_FREQUENCY = 5 * ONE_MINUTE;
export const imagesRepoPath = "/application/themes/alberta/images";

export let gameDisplayXMargin = 0;
export let gameDisplayYMargin = 40;
export let gameDisplayMaxWidth = 1280;

export const buildVersion = process.env.REACT_APP_BUILD_VER || "unknown-build-version";
export const gitHash = process.env.REACT_APP_GIT_HASH || "unknown-git-hash";

export let flagShowTimers = process.env.REACT_APP_SHOW_TIMERS !== "false";
