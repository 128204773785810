export const FETCH_PJP_ACTION = "pjp_fetch";
export const INCREASE_PJP_ACTION = "pjp_increase";
export const FETCH_ERROR_PJP_ACTION = "pjp_fetch_error";

export const FETCH_EXTERNAL_PJP_ACTION = "pjp_external_fetch";

export const fetchExternalPjp = (payload) => (dispatch) => {
    dispatch({ type: FETCH_EXTERNAL_PJP_ACTION, payload: payload || [] });
};

export const fetchPjp = (endpoint) => (dispatch) => {
    let pjpUrl = `${endpoint}/api/pjp/getprogressivejackpots?CurrencyCode=CAD`;

    fetch(pjpUrl)
        .then((res) => {
            return res.json();
        })
        .then(({ ProgressiveJackpots }) => {
            let pjps = (ProgressiveJackpots || []).map((jp) => {
                return {
                    ...jp,
                    fetchDate: Date.now(),
                };
            });
            dispatch({ type: FETCH_PJP_ACTION, payload: pjps });
        })
        .catch(() => {
            dispatch({ type: FETCH_ERROR_PJP_ACTION });
        });
};

export const increasePjp = () => (dispatch) => {
    dispatch({ type: INCREASE_PJP_ACTION });
};
