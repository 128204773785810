const currencySigns = {
    CAD: "$",
};

export const formatNumber = (value, options = {}) => {
    if (isNaN(value)) {
        throw new Error("The value should be a valid number.");
    }

    const roundedValue = Number.parseFloat(value).toFixed(options.decimalPlaces);

    const splitedValue = roundedValue.split(".");
    const beforeDecimal = splitedValue[0];
    const afterDecimal = splitedValue[1];

    let formated = "";

    if (beforeDecimal.length <= 3) {
        formated = beforeDecimal;
    } else {
        const reversed = beforeDecimal.split("").reverse();

        for (let i = 0; i <= reversed.length - 1; i++) {
            formated = `${reversed[i]}${formated}`;

            if ((i + 1) % 3 === 0 && i < reversed.length - 1) {
                formated = `,${formated}`;
            }
        }
    }

    return `${formated}${afterDecimal ? `.${afterDecimal}` : ""}`;
};

export const formatCurrency = (value, currency, options) => {
    const formattedValue = formatNumber(value, options);

    if (!currency || !currencySigns[currency]) {
        throw new Error("Supported currency should be provided.");
    }

    return `${currencySigns[currency]}${formattedValue}`;
};
