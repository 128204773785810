import { isDev, isTest } from "./index";

function log() {
    if (isDev()) {
        console.log(...arguments);
    }
}

function warn() {
    if (isDev()) {
        console.warn(...arguments);
    }
}

function error() {
    if (isDev() || isTest()) {
        console.error(...arguments);
    }
}
export { log, warn, error };
