import { log } from "../../../utils/logger";

const defaultPageMappings = [
    { name: "FAQ", customInfo: "", path: "/help" },
    { name: "FAQ", customInfo: "*", path: "/help" },
    { name: "FAQ", customInfo: "Registration", path: "/helparticle/how-do-i-register-a-play-alberta-account" },
    {
        name: "FAQ",
        customInfo: "GeoLocation",
        reason: "undetermined",
        path: "/helparticle/how-do-i-manage-location-services-on-my-mobile-device",
    },
    {
        name: "FAQ",
        customInfo: "ForgotPassword",
        path: "/helparticle/how-do-i-reset-my-password-if-i-do-not-have-access-to-my-email",
    },
    { name: "FAQ", customInfo: "MyAccount", path: "/help" },
    { name: "FAQ", customInfo: "History", path: "/help" },
    { name: "FAQ", customInfo: "UploadDocuments", path: "/helparticle/how-do-i-upload-documents" },
    { name: "FAQ", customInfo: "PersonalInfo", path: "/helparticle/how-do-i-update-my-personal-details" },
    { name: "FAQ", customInfo: "Notifications", path: "/helparticle/how-do-i-manage-my-preferences" },
    { name: "FAQ", customInfo: "Maintenance", path: "/helparticle/maintenance-schedule" },
    {
        name: "FAQ",
        customInfo: "Geolocation",
        reason: "permission_denied",
        path: "/helparticle/how-do-i-manage-location-services-on-my-mobile-device",
    },
    {
        name: "FAQ",
        customInfo: "Geolocation",
        reason: "browser_not_supported",
        path: "/helparticle/how-do-i-manage-location-services-on-my-mobile-device",
    },
    { name: "GeolocationFAQs", customInfo: "LocationNotVerifiedPopup", path: "/help/location-verify" },
    {
        name: "EmailVerificationFAQ",
        customInfo: "Registration",
        path: "/help/account-info?q=how-do-i-verify-my-email-address-during-registration",
    },
    { name: "Terms", customInfo: "", path: "/terms" },
    { name: "Terms", customInfo: "Registration", path: "/terms" },
    { name: "documentsInformation", customInfo: "", path: "/helparticle/what-documents-are-acceptable" },
    { name: "Privacy", customInfo: "", path: "/privacy" },
    { name: "BonusPolicy", customInfo: "", path: "/terms" },
    { name: "HelpDeposit", customInfo: "*", path: "/helparticle/how-do-i-add-funds-to-my-play-alberta-account" },
    { name: "HelpDeposit", customInfo: "bycredit", path: "/helparticle/how-do-i-add-funds-to-my-play-alberta-account" },
    {
        name: "HelpDeposit",
        customInfo: "interace-transfer",
        path: "/helparticle/how-do-i-add-funds-to-my-play-alberta-account",
    },
    {
        name: "HelpDeposit",
        customInfo: "interaconline",
        path: "/helparticle/how-do-i-add-funds-to-my-play-alberta-account",
    },
    { name: "HelpDepositMethods", customInfo: "", path: "/helparticle/how-do-i-add-funds-to-my-play-alberta-account" },
    { name: "SuccessfulDeposit", customInfo: "", path: "/help" },
    { name: "UnsuccessfulDeposit", customInfo: "", path: "/helparticle/how-do-i-add-funds-to-my-play-alberta-account" },
    { name: "HelpCashout", customInfo: "", path: "/helparticle/how-do-i-withdraw-my-winnings" },
    { name: "HelpCashoutHowTo", customInfo: "", path: "/helparticle/how-do-i-withdraw-my-winnings" },
    { name: "HelpCashoutPolicy", customInfo: "", path: "/terms" },
    { name: "HelpCashoutPolicy", customInfo: "WithdrawError", path: "/terms#withdrawals50" },
    { name: "ExternalBetDetails", customInfo: "", path: "/help" },
    { name: "Bonuses", customInfo: "", path: "/terms" },
    { name: "bonusWagering", customInfo: "", path: "/terms/wagering" },
    { name: "Email", customInfo: "", path: "/contact-us?open=contact" },
    { name: "ChangeAccountDetails", customInfo: "Email", path: "/helparticle/how-do-i-update-my-personal-details" },
    { name: "Play", customInfo: "", path: "/", stayOnPage: true },
    { name: "ResponsibleGaming", customInfo: "", path: "/helparticle/responsible-gambling" },
    { name: "SelfExclusionInformation", customInfo: "", path: "https://aglc.ca/self-exclusion" },
    { name: "GameRules", customInfo: "", path: "/games/{gameSlug}/how-to-play" },
    { name: "AddNewTicket", customInfo: "", path: "/lottery", stayOnPage: true },
    { name: "BonusTerms", customInfo: "", path: "/terms/bonus-terms" },
    { name: "Sports", customInfo: "", path: "/sports", stayOnPage: true },
    { name: "InstantGames", customInfo: "", path: "/", stayOnPage: true },
    {
        name: "GiftCenterRedeemFaq",
        customInfo: "",
        path: "/help/promotions-giveaways-etc?q=how-do-i-use-an-instant-promo-code",
    },
];

export function getInternalPageToOpen(pageKey, customInfo, gamesIdSlugMap) {
    if (pageKey === "GameRules") {
        return getGameRulesPageToOpen(pageKey, customInfo, gamesIdSlugMap);
    }

    let foundPage = getInternalPageToOpen.mappings.find((mapping) => {
        return pageKeyMatches(mapping, pageKey) && customInfoMatches(mapping, customInfo);
    });

    if (!foundPage) {
        foundPage = getInternalPageToOpen.mappings.find((mapping) => {
            return pageKeyMatches(mapping, pageKey) && mapping.customInfo === "*";
        });
    }

    return foundPage && foundPage.path;
}

export function shouldOpenPageInCurrentTab(pageKey, customInfo) {
    const foundPage = getInternalPageToOpen.mappings.find((mapping) => {
        return pageKeyMatches(mapping, pageKey) && customInfoMatches(mapping, customInfo);
    });

    return foundPage && foundPage.stayOnPage;
}

function getGameRulesPageToOpen(pageKey, customInfo, gamesIdSlugMap) {
    const pageMapping = getInternalPageToOpen.mappings.find((mapping) => pageKeyMatches(mapping, pageKey));
    const foundPage = { ...pageMapping };
    if (foundPage) {
        let slug = gamesIdSlugMap.find((map) => map.id === customInfo)?.urlTitle;
        foundPage.path = foundPage.path.replace(/{gameSlug}/, slug);
    }

    return foundPage && foundPage.path;
}

function pageKeyMatches(mapping, pageKey) {
    return mapping.name.toLowerCase() === pageKey.toLowerCase();
}

function customInfoMatches(mapping, customInfo) {
    if (!customInfo) {
        return true;
    }
    if (typeof customInfo === "string") {
        return valuesMatch(customInfo, mapping);
    }

    return valuesMatch(customInfo.key, mapping) && customInfo.reason === mapping.reason?.toLowerCase();
}

function valuesMatch(customInfo, mapping) {
    return customInfo.toLowerCase() === mapping.customInfo.toLowerCase();
}

export function setOpenPageMappings(cmsData) {
    log(`updating openpage list`);
    getInternalPageToOpen.mappings = Object.keys(cmsData).map((key) => {
        const splitKey = key.split("|");
        const valueSplit = cmsData[key].split("|");
        return {
            name: splitKey[0],
            customInfo: splitKey[1] ?? "",
            path: valueSplit[0],
            ...(splitKey.length > 2 && { reason: splitKey[2] }),
            stayOnPage: !!valueSplit[1] && valueSplit[1] !== "false",
        };
    });
}

getInternalPageToOpen.mappings = defaultPageMappings;
