import React, { useEffect, useReducer } from "react";

const initEnvConfig = {
    env: {
        bearerToken: null,
        googleToken: null,
        pjpEndpoint: null,
    },
    initDone: false,
    initError: false,
};

const EnvContext = React.createContext(initEnvConfig);

export default function useEnvConfig() {
    return React.useContext(EnvContext);
}

export function EnvConfigProvider({ children }) {
    const [state, setState] = useReducer((state, action) => {
        return { ...state, ...action };
    }, initEnvConfig);

    useEffect(() => {
        function fetchEnv() {
            fetch("/api/vtl/env")
                .then((res) => res.json())
                .then((data) => {
                    if (data?.env?.bearerToken) {
                        setState({ env: data.env, initDone: true, initError: false });
                    } else {
                        setState({ initDone: false, initError: true });
                    }
                });
        }
        fetchEnv();
    }, []);

    return <EnvContext.Provider value={state}>{children}</EnvContext.Provider>;
}
