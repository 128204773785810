import React, { useEffect, useReducer } from "react";
import { log } from "../../utils/logger";

const initNeoGames = {
    dbg: [],
    instant: [],
};

const NeoGamesContext = React.createContext(initNeoGames);

export function NeoGamesProvider({ children, neo, value = initNeoGames }) {
    const [state, setState] = useReducer((gamesState, action) => {
        return { ...gamesState, ...action };
    }, value);

    useEffect(() => {
        function onGamesListUpdatedHandler(data) {
            log("neogames", data.games);
            setState(data.games);
        }

        neo.registerCallback("onGamesListUpdated", onGamesListUpdatedHandler);
        return () => {
            neo.removeCallback("onGamesListUpdated", onGamesListUpdatedHandler);
        };
    }, [neo]);

    useEffect(() => {
        neo.registerCallback("onInit", neo.updateGamesList);
        return () => {
            neo.removeCallback("onInit", neo.updateGamesList);
        };
    }, [neo]);

    return <NeoGamesContext.Provider value={state}>{children}</NeoGamesContext.Provider>;
}

export default NeoGamesContext;
