import React from "react";

export const isMobile = (userAgent) => {
    let uaToTest = userAgent || navigator.userAgent;

    return isMobileIOS(uaToTest) || /(mobi|tablet|android)/i.test(uaToTest);
};

export function isMobileIOS(uaToTest) {
    return /^(iPhone|iPad|iPod)/.test(uaToTest || navigator.userAgent) || (/(Mac)/i.test(uaToTest) && isTouchDevice());
}

export function isMobileOrSmallScreen(windowInnerWidth = window.innerWidth) {
    return isMobile() || windowInnerWidth <= 768;
}

//https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
export function isTouchDevice() {
    return !!("ontouchstart" in window || navigator.maxTouchPoints);
}

export function useIsTouchDevice() {
    return React.useMemo(isTouchDevice, []);
}
