import { MAX_SESSION_TTL, REFRESH_SESSION_FREQUENCY } from "../../../webapp_config";
import clock from "./clock";
import { log } from "../../../utils/logger";

class PlayerActivity {
    constructor(clockToUse) {
        this.clock = clockToUse || clock;
        this.handleUserInteraction = this.handleUserInteraction.bind(this);
    }

    start() {
        this.setLastInteractionTime();
        this.setLastRefreshTime();
    }

    setLastInteractionTime() {
        this.lastInteractionTime = this.clock.getCurrentTime();
    }

    setLastRefreshTime() {
        this.lastRefreshTime = this.clock.getCurrentTime();
    }

    handleUserInteraction() {
        this.setLastInteractionTime();
        log("lastInteractionTime set");
    }

    checkActivity(refreshSessionCall, onSessionEnd) {
        log("timeSinceLastRefresh", this.timeSinceLastRefresh() / 1000, "sec");
        log("timeSinceLastInteraction", this.timeSinceLastInteraction() / 1000, "sec");
        if (this.sessionTimeHasElapsed()) {
            log("onSessionEnd");
            onSessionEnd();
            return;
        }

        if (this.timeToRefresh() && this.userHasInteracted()) {
            log("refreshSessionCall", "done");
            refreshSessionCall();
            this.setLastRefreshTime();
        }
    }

    sessionTimeHasElapsed() {
        return this.clock.getCurrentTime() - this.lastInteractionTime >= MAX_SESSION_TTL;
    }

    timeToRefresh() {
        return this.timeSinceLastRefresh() >= REFRESH_SESSION_FREQUENCY;
    }

    userHasInteracted() {
        return this.timeSinceLastInteraction() < REFRESH_SESSION_FREQUENCY;
    }

    timeSinceLastInteraction() {
        return this.clock.getCurrentTime() - this.lastInteractionTime;
    }

    timeSinceLastRefresh() {
        return this.clock.getCurrentTime() - this.lastRefreshTime;
    }
}

export default PlayerActivity;
